import { css, createGlobalStyle } from 'styled-components'

export const globalStyles = css`
  :root {
    --app-height: 100%;
  }

 * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  * { min-width: 0; min-height: 0; }
  
  html {
    font-size: 62.5%;
  }
  
  html, body, #root {
    width: 100vw;
    /* min-width: 100vh; */
    height: 100vh;
    overflow: hidden;
  }
  
  #root {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
  
  body {
    /* font-family: "Roboto","Segoe UI","Helvetica Neue",Arial,sans-serif; */
    font-family: "Open Sans","Roboto","Segoe UI","Helvetica Neue",Arial,sans-serif;
    width: 100vw;
    height: 100vh;
    font-size: 1.6rem;
    overflow: hidden;
  }

  html, body {
    overscroll-behavior-y: none;
  }
  body {
      position: fixed;
      overflow: hidden;
  }

  html,
  body,
  #root,
  #modals {
      padding: 0;
      margin: 0;
      overflow: hidden;
      width: 100vw;
      height: 100vh;
      height: var(--app-height);
  }
`

export const GlobalStyles = createGlobalStyle`${globalStyles}`

export default globalStyles